<template>
  <div class="companies-map">
    <ve-bmap
      ref="mapRef"
      height="100%"
      :settings="chartSettings"
      :series="chartSeries"
      :tooltip="chartTooltip"
      :events="chartEvents"
    ></ve-bmap>
    <div class="company-list-box">
      <div style="text-align: right">
        <el-button
          v-if="companyListFlag"
          size="small"
          type="warning"
          icon="el-icon-minus"
          @click="companyListFlag = false"
          >收起</el-button
        >
        <el-button
          v-else
          size="small"
          type="primary"
          icon="el-icon-office-building"
          @click="companyListFlag = true"
          >展开</el-button
        >
      </div>
      <el-collapse-transition>
        <div v-show="companyListFlag" class="company-list">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <el-input
                style="width: 100%"
                v-model="query.keyword"
                size="mini"
                placeholder="输入关键字搜索"
                @input="handleInput"
              >
                <!-- <template slot="prepend">生产经营者名称</template> -->
              </el-input>
            </div>
            <div>
              <el-table
                style="background: transparent"
                border
                fit
                :data="sensorData"
                :show-header="false"
                highlight-current-row
                :max-height="500"
                @row-click="handleRowClick"
              >
                <el-table-column
                  prop="deviceName"
                  align="center"
                ></el-table-column>
              </el-table>
              <el-pagination
                small
                background
                hide-on-single-page
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="query.page"
                :page-sizes="[10, 20, 30, 50]"
                :page-size="query.limit"
                layout=" prev, pager, next"
                :total="sensorTotal"
              ></el-pagination>
            </div>
          </el-card>
        </div>
      </el-collapse-transition>
    </div>
    <el-dialog :title="title" :visible.sync="dialogTableVisible">
      <div style="margin-bottom: 10px">
        <el-date-picker
          v-model="dateRange"
          type="daterange"
          align="right"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
          value-format="yyyy-MM-dd"
        ></el-date-picker>
        <el-button
          type="primary"
          size="small"
          @click="onSearch"
          style="margin-left: 10px"
          >筛选</el-button
        >
        <el-button
          v-if="buttonFlag"
          type="success"
          size="small"
          @click="onChangeChart"
          >切换图表</el-button
        >
      </div>
      <transition name="el-fade-in-linear">
        <div v-show="lineChartFlag">
          <ve-line
            ref="lineChart"
            :loading="lineChartLoading"
            :data="lineChartData"
            :extend="lineChartExtend"
            :settings="lineChartSettings"
          ></ve-line>
        </div>
      </transition>
      <transition name="el-fade-in-linear">
        <div v-show="!lineChartFlag">
          <el-table
            :data="gridData"
            border
            fit
            height="800"
            v-loading="sensorLoading"
          >
            <el-table-column
              align="center"
              property="data"
              label="值"
            ></el-table-column>
            <el-table-column
              align="center"
              property="time"
              label="时间"
            ></el-table-column>
          </el-table>
          <div class="pagination-container" v-show="pageFlag">
            <el-pagination
              background
              @current-change="handleCurrentChangeDialog"
              :current-page="hisQuery.page"
              :page-size="50"
              layout="total, prev, pager, next"
              :total="dialogTotal"
            ></el-pagination>
          </div>
        </div>
      </transition>
    </el-dialog>
  </div>
</template>

<script>
// import axios from "@/util/api.js";
import axios from "../../../util/api";
import api from "../../../util/extra-api";
import { diffTime, deepClone, createHash } from "@/util/util";
import { mapConfig } from "./custom_map_config.js";
import { local } from "../../../util/util";
import dayjs from "dayjs";
import { pickerOptions, seriesOption } from "../sensor_screen/optionData";
import "echarts/lib/component/visualMap";

export default {
  data() {
    this.lineChartExtend = {
      "xAxis.0.axisLabel.rotate": 45,
    };
    this.lineChartSettings = {
      labelMap: {
        time: "时间",
        value: "值",
      },
    };

    this.chartSettings = {
      key: "Ns87bUghsWzPirriDs7uPGPYQOlGUKYQ",
      bmap: {
        center: [120, 30],
        zoom: 5,
        roam: true,
        enableMapClick: false,
        mapStyleV2: { styleJson: mapConfig },
      },
    };
    this.chartTooltip = {
      show: true,
      formatter: function (params, ticket, callback) {
        return `${params.marker}${params.value[3]}`;
      },
    };
    let _this = this;
    this.chartEvents = {
      click: (v) => {
        _this.hisQuery.id = v.data[6];
        _this.dateRange = [];
        _this.buttonFlag = false;
        _this.sensorLoading = true;
        _this.lineChartData = {};
        _this.dialogTableVisible = true;
        _this.lineChartFlag = false;
        const name = v.value[3];
        _this.title = `历史数据[${name}]`;
        _this.hisQuery.companyId = localStorage.getItem("company_id");
        api
          .get("/v1/wx/device/sensorHisData", _this.hisQuery)
          .then((response) => {
            const data = response.data;
            _this.gridData = data.data.map((m) => {
              return {
                data: m.value + m.unit,
                time: m.timeDate,
              };
            });
            _this.sensorLoading = false;
            // console.log(_this.sensorLoading,'结果');
            _this.dialogTotal = data.total;
            _this.pageFlag = true;
          });
        // console.log("v: ", v);
        // _this.$nextTick((_) => {
        //   _this.companyInfo.company_name = v.value[2];
        //   _this.companyInfo.address = v.value[4];
        //   _this.companyInfo.company_type_id = v.value[5];
        //   _this.companyId = v.value[3];
        //   var echarts = this.$refs.mapRef.echarts;
        //   var map = echarts.getModel().getComponent("bmap").getBMap();
        //   var point = new window.BMap.Point(v.value[0], v.value[1]);
        //   var infoWindow = new window.BMap.InfoWindow(
        //     _this.$refs.customInfowindow
        //   );
        //   // if (this.clickTimes == 0) {
        //   //   map.centerAndZoom(point, 14);
        //   // }
        //   map.setCenter(point);
        //   map.openInfoWindow(infoWindow, point);
        //   this.clickTimes++;
        // });
      },
      finished: (_) => {
        // _this.getGeo();
      },
    };
    this.chartSettingsEvaluate = {
      roseType: "radius",
    };
    this.chartExtendEvaluate = {
      legend: {
        textStyle: {
          color: "#ffffff", //字体颜色
        },
      },
    };
    return {
      camId: null,
      timeValue: "",
      imageSrc: "",
      picLoading: false,
      picsVisible: false,
      companyId: null,
      dataType: null,
      barcode: "",
      companyList: [],
      chartSeries: [],
      srlistPage: 1,
      rrlistPage: 1,
      recordData: {},
      companyInfo: {
        company_name: "",
        address: "",
        company_type_id: "",
      },
      storageData: [],
      unitData: [],
      filterForm: {
        flag: 0,
        goodsName: "",
        batchNumber: "",
        gtin: "",
      },
      filterRecordForm: {
        goodsName: "",
        gtin: "",
        batchNumber: "",
        carNumber: "",
        goodsType: 5,
      },
      recordDialogVisible: false,
      supDialogVisible: false,
      storageDialogVisible: false,
      unitDialogVisible: false,
      picDialogVisible: false,
      attachList: [],
      reportList: [],
      clickTimes: 0,
      level: "",
      reason: "",
      file: "",
      search: "",
      actionPath:
        process.env.VUE_APP_BASE_API + "/api/v1/department/sup_upload",
      baseUrl: process.env.VUE_APP_BASE_API,
      companyListFlag: true,
      hashFlag: false,
      hashVal: createHash(30),
      // listQuery: {
      //   pager_offset: "0",
      //   pager_openset: "100",
      //   department_type: "",
      // },
      query: {
        keyword: "",
        page: 1,
        limit: 10,
        companyId: null,
      },
      currentPage: 1,
      sensorTotal: null,
      listLoading1: false,
      list1: [],
      // listQuery1: {
      //   opt: "1",
      //   pager_offset: "0",
      //   count: "20",
      //   company_id: "",
      // },
      currentPage1: 1,
      listTotal1: 0,
      table1DialogVisible: false,
      table2DialogVisible: false,
      table3DialogVisible: false,
      table4DialogVisible: false,
      camDialogVisible: false,
      listLoading2: false,
      list2: [],
      // listQuery2: {
      //   pager_offset: "0",
      //   count: "20",
      //   name: "",
      //   base_name: "",
      //   company_id: "",
      // },
      // listQuery3: {
      //   opt: "1",
      //   pager_offset: "0",
      //   count: "20",
      //   planting_id: "",
      // },
      // listQuery4: {
      //   opt: "1",
      //   pager_offset: "0",
      //   count: "20",
      //   planting_id: "",
      // },
      // listQuery5: {
      //   trx_id: "",
      //   opt: "1",
      //   pager_offset: "0",
      //   count: "20",
      //   company_id: "",
      //   add_time: "",
      //   end_time: "",
      // },
      // listQuery6: {
      //   company_id: "",
      //   page: 1,
      //   count: 9,
      // },
      camListQuery: {
        company_id: "",
        page: 1,
        count: 6,
      },
      camList: [],
      // currentPage2: 1,
      // currentPage3: 1,
      // currentPage4: 1,
      // currentPage5: 1,
      // currentPage6: 1,
      listTotal2: 0,
      plantingGoodsList: [],
      plantingGoodsListTotal: 0,
      goodsDialogVisible: false,
      farmRecordList: [],
      farmRecordListTotal: 0,
      farmDialogVisible: false,
      flowRecordList: [],
      flowRecordListTotal: 0,
      flowDialogVisible: false,
      salesGoodsRecordList: [],
      salesGoodsRecordListTotal: 0,
      salesGoodsDialogVisible: false,
      url: "",
      url1: [],
      // showViewer: false,
      // showViewer1: false,
      // pickerOptions: {
      //   shortcuts: [
      //     {
      //       text: "最近一周",
      //       onClick(picker) {
      //         const end = new Date();
      //         const start = new Date();
      //         start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
      //         picker.$emit("pick", [start, end]);
      //       },
      //     },
      //     {
      //       text: "最近一个月",
      //       onClick(picker) {
      //         const end = new Date();
      //         const start = new Date();
      //         start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
      //         picker.$emit("pick", [start, end]);
      //       },
      //     },
      //     {
      //       text: "最近三个月",
      //       onClick(picker) {
      //         const end = new Date();
      //         const start = new Date();
      //         start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
      //         picker.$emit("pick", [start, end]);
      //       },
      //     },
      //   ],
      // },
      time: [],
      showHACCP: false,
      showHACCPDetail: false,
      // haccpQuery1: {
      //   page: 1,
      //   limit: 10,
      //   keyword: "",
      //   companyId: null,
      // },
      // haccpQuery2: {
      //   page: 1,
      //   limit: 10,
      //   keyword: "",
      //   companyId: null,
      // },
      // haccpDetailQuery: {
      //   page: 1,
      //   limit: 10,
      //   keyword: "",
      //   companyId: null,
      // },
      haccpList: [
        {
          id: 1,
          name: "方便面",
          count: 10,
          createTime: "2022-3-21 10:52:00",
        },
        {
          id: 2,
          name: "牛肉干",
          count: 10,
          createTime: "2022-3-21 10:52:00",
        },
      ],
      haccpDetailList: [],
      showEvaluate: false,
      evaluateList: [],
      showEvaluateChart: true,
      tableKey: 0, // 表格的键
      evaluateTableList: null, // 表格的数据
      evaluateTotal: null, // 表格的数据数量
      listLoading: true, // 表格加载状态
      tableHeight: 450,
      // evaluateListQuery: {
      //   companyId: null,
      //   page: 1, // 表格的页面值
      //   limit: 20, // 表格的单页数量
      // },
      imgList: [],
      imgDialogWidth: null,
      replyDialogVisible: false,
      imgVisible: false,
      haccpTotal: 0,
      haccpDetailTotal: 0,
      haccpCurrentPage: 1,
      haccpDetailCurrentPage: 1,
      HACCPDetailId: null,
      haccpType: 0,

      sensorData: [],

      dialogTableVisible: false,
      dateRange: "",
      pickerOptions,
      buttonFlag: false,
      lineChartFlag: false,
      gridData: [],
      sensorLoading: false,
      pageFlag: false,
      hisQuery: {
        id: null,
        page: 1,
        companyId: 2582225,
        limit: 50,
        startTime: "",
        endTime: "",
      },
      dialogTotal: 0,
      lineChartData: {},
      title: "",
      lineChartLoading: false,

    };
  },
  computed: {},
  created() {},
  filters: {
    tagFormat(status) {
      const tagMap = {
        0: "primary",
        1: "success",
      };
      return tagMap[status];
    },
    statusFormat(status) {
      const statusMap = {
        0: "未收货",
        1: "已收货",
      };
      return statusMap[status];
    },
    timeFormat(timeString) {
      return diffTime(timeString);
    },
    // infoFilter(info) {
    //   let goodsCategory = JSON.parse(info);
    //   if (goodsCategory) {
    //     if (goodsCategory[0] == "国产" || goodsCategory[0] == "") {
    //       return "国产";
    //     } else if (goodsCategory[0] == "进口") {
    //       return "进口";
    //     } else if (goodsCategory[0] == "农产品") {
    //       return "农产品";
    //     } else if (goodsCategory[0] == "农资") {
    //       return "农资";
    //     }
    //   } else {
    //     return "国产";
    //   }
    // },
    // tagFilter(status) {
    //   //console.log("status: ", status);
    //   const tagMap = {
    //     0: "success",
    //     1: "warning",
    //   };
    //   return tagMap[status];
    // },
    // statusFilter(status) {
    //   const statusMap = {
    //     0: "种养中",
    //     1: "种养结束",
    //   };
    //   return statusMap[status];
    // },
  },
  mounted() {
    this.companyId = localStorage.getItem("company_id");
    this.getSensorInfo(1);
    // this.userData = local.get("user");
    // this.listQuery.department_type = String(this.userData.department_type);
    this.initMap();
  },
  watch: {
    "lineChartData.rows"(v) {
      this.$nextTick((_) => {
        this.$refs[`lineChart`].echarts.resize();
      });
    },
    dialogTableVisible(v) {
      if (!v) {
        this.hisQuery.startTime = "";
        this.hisQuery.endTime = "";
        this.hisQuery.page = 1;
      }
    },
    showEvaluate(v) {
      if (!v) this.showEvaluateChart = true;
    },
    showHACCP(v) {
      if (!v) {
        this.haccpQuery1.page = 1;
        this.haccpQuery1.keyword = "";
        this.haccpCurrentPage = 1;
      }
    },
    showHACCPDetail(v) {
      if (!v) {
        this.haccpQuery2.page = 1;
        this.haccpDetailCurrentPage = 1;
      }
    },
  },
  methods: {
    handleCurrentChangeDialog(val) {
      this.hisQuery.page = val;
      this.sensorLoading = true;
      api.get("/v1/wx/device/sensorHisData", this.hisQuery).then((response) => {
        const data = response.data;
        this.gridData = data.data.map((m) => {
          return {
            data: m.value + m.unit,
            time: m.timeDate,
          };
        });
        this.dialogTotal = data.total;
        this.sensorLoading = false;
      });
    },
    onChangeChart() {
      this.lineChartFlag = !this.lineChartFlag;

      if (this.lineChartFlag) {
        this.lineChartLoading = true;
        api
          .get("/v1/wx/device/sensorHisData", this.hisQuery)
          .then((response) => {
            const data = response.data.data;
            this.lineChartData = {
              columns: ["timeDate", "value"],
              rows: data,
            };
            this.lineChartLoading = false;
          });
      }
    },
    onSearch() {
      if (this.dateRange && this.dateRange.length > 0) {
        this.sensorLoading = true;
        this.hisQuery.page = 1;
        this.pageFlag = true;
        this.buttonFlag = true;
        this.hisQuery.startTime = this.dateRange[0];
        this.hisQuery.endTime = this.dateRange[1];
        api
          .get("/v1/wx/device/sensorHisData", this.hisQuery)
          .then((response) => {
            const data = response.data;
            this.gridData = data.data.map((m) => {
              return {
                data: m.value + m.unit,
                time: m.timeDate,
              };
            });
            this.dialogTotal = data.total;
            this.sensorLoading = false;
          });
      } else {
        this.$message({
          message: "请先选择时间区间",
          type: "warning",
        });
      }
    },
    getSensorInfo(page) {
      this.query.companyId = this.companyId ==='undefined'?null:this.companyId;

      this.query.page = page;
      api.get("/v1/pc/device/gisList", this.query).then((res) => {
        if (res.code === 200) {
          // console.log(res.data);
          this.sensorTotal = res.total;
          this.sensorData = res.data;
          let sensorData = this.convertData(res.data);
          let arr = [];
          sensorData.forEach((item, index) => {
            arr.push({
              type: "effectScatter",
              coordinateSystem: "bmap",
              zlevel: 2,
              rippleEffect: {
                //涟漪特效
                period: 4, //动画时间，值越小速度越快
                brushType: "stroke", //波纹绘制方式 stroke, fill
                scale: 4, //波纹圆环最大限制，值越大波纹越大
              },
              symbol: "circle",
              itemStyle: {
                normal: {
                  color: "#19d4ae",
                },
              },
              data: item,
            });
          });
          // console.log(this.sensorData,'传感器');
          // console.log(this.chartSeries,'数据');
          this.chartSeries = arr;
          this.getGeo();
        }
      });
    },
    checkEvaluate() {
      this.evaluateListQuery.companyId = this.companyId;
      this.getEvaluateTable();
    },
    getEvaluateTable() {
      api
        .get("/v1/pc/evaluation/evaluateList", this.evaluateListQuery)
        .then((response) => {
          const results = response.data;
          this.evaluateTableList = results;
          this.evaluateTotal = response.total;
          this.showEvaluateChart = false;
        })
        .catch((err) => {
          this.$message({
            type: "warning",
            message: err,
          });
        });
    },
    // // 表格单页数据数量的切换
    // handleEvaluateSizeChange(val) {
    //   this.listQuery.count = val;
    //   this.getEvaluateTable();
    // },
    // // 表格页数的切换
    // handleEvaluateCurrentChange(val) {
    //   this.listQuery.page = val;
    //   this.getEvaluateTable();
    // },
    showImg(row) {
      this.imgList = row.imgList;
      this.imgVisible = true;
    },
    handleEvaluate() {
      api
        .get("/v1/pc/evaluation/evaluateChartData", {
          companyId: this.companyId,
        })
        .then((res) => {
          if (res.code === 200) {
            res.data.forEach((r) => {
              r.chartData = {};
              r.chartData.columns = ["key", "value"];
              r.chartData.rows = r.data;
              delete r.data;
            });
            this.evaluateList = res.data;
            this.showEvaluate = true;
          }
        })
        .catch((err) => {
          this.$message({
            type: "warning",
            message: err,
          });
        });
    },
    goHACCPDetail(id) {
      this.HACCPDetailId = id;
      let data = {
        id,
        ...this.haccpQuery2,
      };

      api.get("/v1/pc/haccpScreen/haccpRecordList", data).then((res) => {
        if (res.code === 200) {
          this.showHACCPDetail = true;
          this.haccpDetailTotal = res.total;
          this.haccpDetailList = res.data;
        }
      });
      //this.showHACCPDetail = true
    },
    currentChange(page) {
      this.haccpCurrentPage = page;
      this.haccpQuery1.page = page;
      this.handleHACCP();
    },
    currentDetailChange(page) {
      this.haccpDetailCurrentPage = page;
      this.haccpQuery2.page = page;
      this.goHACCPDetail(this.HACCPDetailId);
    },
    handleHACCP(action) {
      if (action) {
        this.haccpQuery1.page = 1;
        this.haccpCurrentPage = 1;
      }
      this.haccpQuery1.companyId = this.companyId;
      api.get("/v1/pc/haccpScreen/proList", this.haccpQuery1).then((res) => {
        if (res.code === 200) {
          this.haccpList = res.data;
          this.haccpTotal = res.total;
          this.showHACCP = true;
          this.haccpType = 0;
        }
      });
      //this.showHACCP = true
    },
    handleCateringHACCP(action) {
      if (!action) {
        this.haccpQuery1.page = 1;
        this.haccpCurrentPage = 1;
      } else {
        this.haccpQuery1.page = action;
        this.haccpCurrentPage = action;
      }
      this.haccpQuery1.companyId = this.companyId;
      api
        .get("/v1/pc/haccpScreen/cateringHaccpRecordList", this.haccpQuery1)
        .then((res) => {
          if (res.code === 200) {
            this.showHACCPDetail = true;
            this.haccpDetailTotal = res.total;
            this.haccpDetailList = res.data;
            this.haccpType = 1;
          }
        });
      //this.showHACCP = true
    },
    handleReplyPics(cam_id) {
      this.timeValue = dayjs().format("YYYY-MM-DD HH:mm");
      this.camId = cam_id;
      this.picLoading = true;
      axios
        .get("/pc/company-cam/camImg", {
          camId: this.camId,
          time: this.timeValue,
        })
        .then((response) => {
          this.imageSrc = response.data;
          this.picLoading = false;
          this.picsVisible = true;
        });
    },
    handleTimeChange(time) {
      this.picLoading = true;
      // cam
      //         .get_cam_pic({ cam_id: this.camId, time: this.timeValue })
      //         .then((response) => {
      //           this.src = response.data.data;
      //           this.picLoading = false;
      //         });
    },
    handlePre() {
      this.picLoading = true;
      this.timeValue = dayjs(this.timeValue)
        .subtract(1, "minute")
        .format("YYYY-MM-DD HH:mm");
      axios
        .get("/pc/company-cam/camImg", {
          camId: this.camId,
          time: this.timeValue,
        })
        .then((response) => {
          this.imageSrc = response.data;
          this.picLoading = false;
        });
    },
    handleNext() {
      this.picLoading = true;
      this.timeValue = dayjs(this.timeValue)
        .add(1, "minute")
        .format("YYYY-MM-DD HH:mm");
      axios
        .get("/pc/company-cam/camImg", {
          camId: this.camId,
          time: this.timeValue,
        })
        .then((response) => {
          this.imageSrc = response.data;
          this.picLoading = false;
        });
    },
    initMap() {
      // axios
      //   .get(`/pc/company/company-map-list/1`, this.listQuery)
      //   .then((response) => {
      //     let res = response.data.company_list;
      //     this.total = response.data.pager_count;
      //     this.companyList = deepClone(res);
      //     let companiesData = this.convertData(res);
      //     let arr = [];
      //     companiesData.forEach((item, index) => {
      //       arr.push({
      //         type: "effectScatter",
      //         coordinateSystem: "bmap",
      //         zlevel: 2,
      //         rippleEffect: {
      //           //涟漪特效
      //           period: 4, //动画时间，值越小速度越快
      //           brushType: "stroke", //波纹绘制方式 stroke, fill
      //           scale: 4, //波纹圆环最大限制，值越大波纹越大
      //         },
      //         symbol: "circle",
      //         itemStyle: {
      //           normal: {
      //             color: "#19d4ae",
      //           },
      //         },
      //         data: item,
      //       });
      //     });
      //     this.chartSeries = arr;
      //     this.getGeo();
      //   });
    },
    afterSet: function (echarts) {
      var bmap = echarts.getModel().getComponent("bmap").getBMap();
      bmap.addControl(new window.BMap.MapTypeControl());
    },
    handleSizeChange(val) {
      this.query.limit = val;
      this.getSensorInfo(this.query.page);
    },
    handleCurrentChange(val) {
      this.getSensorInfo(val);
      // this.currentPage = val;
      // this.listQuery.pager_offset =
      //   (val - 1) * this.listQuery.pager_openset + "";
      // this.initMap();
    },
    restFilterForm() {
      this.filterForm = {
        flag: 0,
        goodsName: "",
        batchNumber: "",
        gtin: "",
      };
    },
    restFilterRecordForm() {
      this.filterRecordForm = {
        goodsName: "",
        gtin: "",
        batchNumber: "",
        carNumber: "",
        goodsType: 5,
      };
    },
    convertData(data) {
      let result = [];
      for (let i = 0; i < data.length; i++) {
        let formattedData = [
          [
            data[i].longitude,
            data[i].latitude,
            data[i].companyName,
            data[i].deviceName,
            data[i].address,
            data[i].deviceId,
            data[i].id,
          ],
        ];
        result.push(formattedData);
      }
      return result;
    },
    async handleRecord() {
      this.srlistPage = 1;
      this.rrlistPage = 1;
      await Promise.all([this.fetchSendRecords(), this.fetchReceiveRecords()]);
      this.recordDialogVisible = true;
    },
    async fetchSendRecords() {
      await this.$extraApi
        .get(`/v1/pc/area/receiptAndDeliveryData`, {
          companyId: this.companyId,
          ...this.filterRecordForm,
          type: 1,
          page: this.srlistPage,
        })
        .then((response) => {
          let res = response.data;
          res.forEach((item) => {
            item.hashFlag = false;
            item.hashVal = createHash(30);
          });
          this.$set(this.recordData, "send_out_records", res);
          this.$set(this.recordData, "send_out_records_total", response.total);
        });
    },
    async fetchReceiveRecords() {
      await this.$extraApi
        .get(`/v1/pc/area/receiptAndDeliveryData`, {
          companyId: this.companyId,
          ...this.filterRecordForm,
          type: 2,
          page: this.rrlistPage,
        })
        .then((response) => {
          let res = response.data;
          res.forEach((item) => {
            item.hashFlag = false;
            item.hashVal = createHash(30);
          });
          this.$set(this.recordData, "received_records", res);
          this.$set(this.recordData, "received_records_total", response.total);
        });
    },
    handleCurrentChangeSr(val) {
      this.srlistPage = val;
      this.fetchSendRecords();
    },
    handleCurrentChangeRr(val) {
      this.rrlistPage = val;
      this.fetchReceiveRecords();
    },
    handleStorage() {
      this.$extraApi
        .get(`/v1/pc/area/store`, {
          companyId: this.companyId,
          limit: 1000,
          ...this.filterForm,
        })
        .then((response) => {
          let res = response.data;
          this.storageData = res;
          this.storageDialogVisible = true;
        });
    },
    handleUnit() {
      this.$extraApi
        .get(`/v1/pc/area/supply`, { companyId: this.companyId })
        .then((response) => {
          let res = response.data;
          this.unitData = res;
          this.unitDialogVisible = true;
        });
    },
    handleSup() {
      this.level = "";
      this.reason = "";
      this.file = "";
      this.supDialogVisible = true;
    },
    handleRemove(file, fileList) {
      this.file = "";
    },
    handlePreview(file) {},
    handleSuccess(file, fileList) {
      this.file = file.data.file_name;
    },
    async recordSortChange({ column, prop, order }, type) {
      this.filterRecordForm.sort_key = type;
      this.filterRecordForm.sort_status = order;
      await this.handleRecord();
    },
    handleCreateSup() {
      sup_record
        .save({
          company_id: this.companyId,
          level: this.level,
          reason: this.reason,
          file: this.file,
        })
        .then((response) => {
          this.$notify({
            title: "成功",
            message: "监管成功",
            type: "success",
          });
          this.supDialogVisible = false;
        });
    },
    sortChange({ column, prop, order }) {
      this.filterForm.sort_key = prop;
      order == "ascending"
        ? (this.filterForm.sort_status = 1)
        : (this.filterForm.sort_status = 0);
      this.handleStorage();
    },
    handleRowClick(row, column, event) {
      if (row.longitude && row.latitude) {
        this.$nextTick((_) => {
          this.dateRange = [];
          this.buttonFlag = false;
          this.sensorLoading = true;
          this.lineChartData = {};
          this.dialogTableVisible = true;
          this.lineChartFlag = false;
          console.log(row,111);
          this.hisQuery.id = row.id;
          const name =row.deviceName
          this.title = `历史数据[${name}]`;
          this.hisQuery.companyId = this.companyId;
          api
            .get("/v1/wx/device/sensorHisData", this.hisQuery)
            .then((response) => {
              const data = response.data;
              this.dialogTotal=response.data.total
              this.gridData = data.data.map((m) => {
                return {
                  data: m.value + m.unit,
                  time: m.timeDate,
                };
              });
              this.sensorLoading = false;
              this.total = data.total;
              this.pageFlag = true;
            });

          // this.companyInfo.company_name = row.company_name;
          // this.companyInfo.address = row.address;
          // this.companyInfo.company_type_id = row.company_type_id;
          // this.companyId = row.company_id;
          let echarts = this.$refs.mapRef.echarts;
          let map = echarts.getModel().getComponent("bmap").getBMap();
          let point = new window.BMap.Point(row.longitude, row.latitude);
          // let infoWindow = new window.BMap.InfoWindow(
          //   this.$refs.customInfowindow
          // );
          // if (this.clickTimes == 0) {
          //   map.centerAndZoom(point, 14);
          // }
          map.setCenter(point);
          // map.openInfoWindow(infoWindow, point);
          this.clickTimes++;
        });
      } else {
        this.$message({
          message: "缺少定位信息",
          type: "warning",
        });
      }
    },
    // handleTable1() {
    //   this.listQuery1.company_id = this.companyId;
    //   this.initData1();
    //   this.table1DialogVisible = true;
    // },
    // handleTable2() {
    //   this.listQuery2.company_id = this.companyId;
    //   this.initData2();
    //   this.table2DialogVisible = true;
    // },
    // handleTable3() {
    //   this.listQuery5.company_id = this.companyId;
    //   this.listQuery5.trx_id = "";
    //   this.time = [];
    //   this.initData3();
    //   this.table3DialogVisible = true;
    // },
    // handleCam() {
    //   this.camListQuery.company_id = this.companyId;
    //   //this.camListQuery.company_id = "50111"
    //   this.initCam();
    //   this.camDialogVisible = true;
    // },
    initData3() {
      if (this.time.length > 0) {
        this.listQuery5.add_time = this.time[0];
        this.listQuery5.end_time = this.time[1];
      }
      axios.get("/pc/company-flow-record/list", this.listQuery5).then((res) => {
        if (res.code == 200) {
          this.flowRecordList = res.data.company_flow_list;
          this.flowRecordListTotal = res.data.company_flow_count;
        }
      });
    },
    initCam() {
      axios.get("/pc/company-cam/list", this.camListQuery).then((res) => {
        if (res.code == 200) {
          this.camList = res.data.cam_list;
        }
      });
    },
    getGeo() {
      setTimeout((_) => {
        let pointArr = [];
        this.sensorData.forEach((item) => {
          pointArr.push(new window.BMap.Point(item.longitude, item.latitude));
          // pointArr.push({
          //   lat: Number(item.latitude),
          //   lng: Number(item.longitude),
          // });
        });
        let echarts = this.$refs.mapRef.echarts;
        let map = echarts.getModel().getComponent("bmap").getBMap();
        // console.log(pointArr,'报错');
        let view = map.getViewport(eval(pointArr));
        // console.log(view, "view");
        // let mapZoom = view.zoom > 2 ? view.zoom - 1 : view.zoom;
        // console.log(mapZoom,111);
        let mapZoom = 19;
        let centerPoint = view.center;
        map.centerAndZoom(centerPoint, mapZoom);
      }, 200);

      // let echarts = this.$refs.mapRef.echarts;
      // let map = echarts.getModel().getComponent("bmap").getBMap();
      // let myGeo = new window.BMap.Geocoder();
      // // 将地址解析结果显示在地图上，并调整地图视野
      // myGeo.getPoint(
      //   this.province + this.city + this.district,
      //   function (point) {
      //     if (point) {
      //       map.centerAndZoom(point, 14);
      //     }
      //   },
      //   this.city
      // );
    },
    // verifyHash() {
    //   this.hashFlag = !this.hashFlag;
    // },
    // handleViewPic(row) {
    //   this.attachList = [];
    //   this.reportList = [];
    //   const { qualificationUrl, realPics } = row;
    //   if (realPics && realPics !== "null") {
    //     let realPicsTemp = JSON.parse(realPics);
    //     this.attachList = realPicsTemp.map((item) => item.url);
    //   }
    //   if (qualificationUrl && qualificationUrl !== "null") {
    //     let qualificationUrlTemp = JSON.parse(qualificationUrl);
    //     if (qualificationUrlTemp) {
    //       qualificationUrlTemp.forEach((item) => {
    //         if (item.length) {
    //           item.forEach((f) => {
    //             this.reportList.push(f.picUrl);
    //           });
    //         } else {
    //           this.reportList.push(item.picUrl);
    //         }
    //       });
    //     }
    //   }
    //   if (this.attachList.length > 0 || this.reportList.length > 0) {
    //     this.picDialogVisible = true;
    //   } else {
    //     this.$message({
    //       message: "无图片",
    //       type: "warning",
    //     });
    //   }
    // },
    handleInput(val) {
      // this.initMap();
      this.query.keyword=val
      this.getSensorInfo(1)
    },

    initData1() {
      this.listLoading1 = true;
      axios
        .get("/pc/company-planting-area/list", this.listQuery1)
        .then((response) => {
          let data = response.data.planting_list;
          data.forEach((item) => {
            if (item.img_url) {
              item.imgs = JSON.parse(item.img_url);
            }
          });
          this.list1 = data;
          this.listTotal1 = response.data.planting_count;
          this.listLoading1 = false;
        });
    },
    // handleSizeChange1(val) {
    //   this.listQuery1.count = val;
    //   this.initData1();
    // },
    // handleCurrentChange1(val) {
    //   this.currentPage1 = val;
    //   this.listQuery1.pager_offset = String((val - 1) * 10);
    //   this.initData1();
    // },
    initData2() {
      this.listLoading2 = true;
      axios
        .get("/pc/company-planting-category/list", this.listQuery2)
        .then((response) => {
          let data = response.data.planting_category_list;
          this.list2 = data;
          this.listTotal2 = response.data.planting_category_count;
          this.listLoading2 = false;
        });
    },
    // handleSizeChange2(val) {
    //   this.listQuery2.count = val;
    //   this.initData2();
    // },
    // handleCurrentChange2(val) {
    //   this.currentPage2 = val;
    //   this.listQuery2.pager_offset = String((val - 1) * 10);
    //   this.initData2();
    // },
    // handleGoods(id) {
    //   if (id) {
    //     this.listQuery3.planting_id = id;
    //   }
    //   this.plantingGoodsList = [];
    //   axios
    //     .get("/pc/company-planting-category/goodsList", this.listQuery3)
    //     .then((response) => {
    //       let data = response.data.planting_goods_list;
    //       data.forEach((item) => {
    //         item.imgs = [];
    //         if (item.img_list) {
    //           item.imgs = JSON.parse(item.img_list);
    //         }
    //       });
    //       this.plantingGoodsList = data;
    //       this.plantingGoodsListTotal = response.data.planting_goods_count;
    //     });
    //   this.goodsDialogVisible = true;
    // },
    // handleFarmRecord(id) {
    //   if (id) {
    //     this.listQuery4.planting_id = id;
    //   }
    //   this.farmRecordList = [];
    //   axios
    //     .get("/pc/company-planting-category/farmRecordList", this.listQuery4)
    //     .then((response) => {
    //       let data = response.data.planting_farmRecord_list;
    //       data.forEach((item) => {
    //         item.imgs = [];
    //         if (item.img_url) {
    //           item.imgs = item.img_url;
    //         }
    //       });
    //       this.farmRecordList = data;
    //       this.farmRecordListTotal = response.data.farmRecord_count;
    //     });
    //   this.farmDialogVisible = true;
    // },
    // handleSalesGoods(id) {
    //   this.table4DialogVisible = true;
    //   if (id) {
    //     this.listQuery6.company_flow_id = id;
    //     this.listQuery6.goods_name = "";
    //   }
    //   axios
    //     .get("/pc/company-flow-record/goodsList", this.listQuery6)
    //     .then((res) => {
    //       if (res.code == 200) {
    //         this.salesGoodsRecordList = res.data.company_flow_goods_list;
    //         this.salesGoodsRecordListTotal = res.data.company_flow_goods_count;
    //       }
    //     });
    // },
    // handleSizeChange3(val) {
    //   this.listQuery3.count = val;
    //   this.handleGoods();
    // },
    // handleCurrentChange3(val) {
    //   this.currentPage3 = val;
    //   this.listQuery3.pager_offset = String((val - 1) * 10);
    //   this.handleGoods();
    // },
    // handleSizeChange4(val) {
    //   this.listQuery4.count = val;
    //   this.handleFarmRecord();
    // },
    // handleCurrentChange4(val) {
    //   this.currentPage4 = val;
    //   this.listQuery4.pager_offset = String((val - 1) * 10);
    //   this.handleFarmRecord();
    // },
    // handleSizeChange5(val) {
    //   this.listQuery5.count = val;
    //   this.initData3();
    // },
    // handleCurrentChange5(val) {
    //   this.currentPage5 = val;
    //   this.listQuery5.pager_offset = String((val - 1) * 10);
    //   this.initData3();
    // },
    // handleSizeChange6(val) {
    //   this.listQuery6.count = val;
    //   this.handleSalesGoods();
    // },
    // handleCurrentChange6(val) {
    //   this.currentPage6 = val;
    //   this.listQuery6.pager_offset = String((val - 1) * 10);
    //   this.handleSalesGoods();
    // },

    // handleViewPic2(imgs) {
    //   let url = imgs.map((item) => item.url);
    //   this.url1 = url;
    //   this.showViewer1 = true;
    // },
    // closeViewer1() {
    //   this.showViewer1 = false;
    // },
  },
};
</script>
<style lang="scss" scoped >
.companies-map {
  position: relative;
  height: 100%;
}
.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}
.chain-block-box {
  position: absolute;
  top: 28px;
  left: 15px;
  z-index: 1099;
  padding: 5px 10px;
  background-color: #1a366a;
  color: #f2f6fc;
  cursor: default;
}
.hash-value {
  color: #409eff;
  cursor: pointer;
  z-index: 3;
}
.hash-result {
  color: #67c23a;
  cursor: pointer;
}
.company-list-box {
  position: absolute;
  top: 28px;
  right: 20px;
  z-index: 1099;
}
.company-list {
  background-color: aliceblue;
}
@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
  .company-list {
    background: hsla(0, 0%, 100%, 0.35);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
  }
}
.companies-map .company-list .box-card {
  width: 350px;
  background-color: inherit;
}
.companies-map .company-list .box-card .el-table__row {
  cursor: pointer;
}
/* .companies-map
  .company-list
  .box-card
  .el-table--striped
  .el-table__body
  tr.el-table__row--striped.current-row
  td,
.el-table__body tr.current-row > td {
  background-color: #ffec8b;
} */
.companies-map .company-list .el-table__body tr.current-row > td {
  background-color: #ffec8b;
}

.companies-map .company-list .box-card .el-table tbody tr:hover > td {
  background-color: #409eff;
  color: white;
}
.el-image-viewer__close {
  color: white;
}
.el-image-viewer__wrapper {
  z-index: 9999999 !important;
}

.card-item {
  flex: 1;
  width: 31%;
  min-width: 31%;
  max-width: 31%;
  height: 400px;
  background: transparent;
  margin-bottom: 30px;
  border: 0.5px solid aqua;
  border-radius: 6px;
}
.card-item-title {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px 20px;
  background: transparent;
  text-align: center;
  font-size: 18px;
  color: #fff;
  border-bottom: 1px solid gainsboro;
}

.card-item-content {
  padding: 20px 10px;
  height: 20vw;
}

/*.el-dialog__body  {*/
/*  padding: 0;*/
/*}*/
$color: #2194e0;
.button_hover {
  width: 80px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  text-decoration: none;
  color: #d3d7d4;
  border: 2px solid $color;
  font-size: 14px;
  display: inline-block;
  border-radius: 15px;
  transition: all 0.2s ease-in-out;
  position: relative;
  overflow: hidden;
  margin-right: 10px;
  &:before {
    content: "";
    background-color: rgba(255, 255, 255, 0.5);
    height: 100%;
    width: 3em;
    display: block;
    position: absolute;
    top: 0;
    left: -4.5em;
    transform: skewX(-45deg) translateX(0);
    transition: none;
  }
  &:hover {
    cursor: pointer;
    background-color: $color;
    color: #fff;
    border-bottom: 4px solid darken($color, 10%);
    &:before {
      transform: skewX(-45deg) translateX(13.5em);
      transition: all 0.5s ease-in-out;
    }
  }
}

.activated {
  color: #1d953f;
}
/deep/.el-table--scrollable-y ::-webkit-scrollbar {
  display: none;
}
.haccp-bg {
  transform-origin: 0px 0px 0px;
  background-image: url("../../../assets/image/mapbg.png");
  background-size: cover;
  background-position: 50% 0;
  background-color: rgb(0, 0, 0);
  min-width: auto;
  width: 100%;
  min-height: auto;
  height: 100%;
  overflow: auto;
  overflow-x: hidden;
}
.evaBg {
  transform-origin: 0px 0px 0px;
  background-image: url("../../../assets/image/mapbg.png");
  background-size: cover;
  background-position: 50% 0;
  background-color: rgb(0, 0, 0);
  min-width: auto;
  width: 100%;
  min-height: auto;
  height: 100%;
  overflow: hidden;
  overflow-x: hidden;
}
.el-card {
  background-color: transparent;
}
.button {
  width: 80px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  text-decoration: none;
  color: #33a3dc;
  border: 2px solid $color;
  font-size: 16px;
  display: inline-block;
  border-radius: 15px;
  transition: all 0.2s ease-in-out;
  position: relative;
  overflow: hidden;
  margin-right: 10px;
  &:before {
    content: "";
    background-color: rgba(255, 255, 255, 0.5);
    height: 100%;
    width: 3em;
    display: block;
    position: absolute;
    top: 0;
    left: -4.5em;
    transform: skewX(-45deg) translateX(0);
    transition: none;
  }
  &:hover {
    cursor: pointer;
    background-color: $color;
    color: #fff;
    border-bottom: 4px solid darken($color, 10%);
    &:before {
      transform: skewX(-45deg) translateX(13.5em);
      transition: all 0.5s ease-in-out;
    }
  }
}
.hover {
  cursor: pointer;
  &:hover {
    cursor: pointer;
    color: $color;
    &:before {
      transform: skewX(-45deg) translateX(13.5em);
      transition: all 0.5s ease-in-out;
    }
  }
}
</style>
